import { useMemo } from 'react';

import { DayPassFilterType } from '@/app/[locale]/(search)/coworking/_components/DayPassFilters';
import { getDayPassFilterFromQueryParams } from '@/app/[locale]/(search)/coworking/utils';

import useSearchPageParams from './useSearchPageParams';

export default function useDayPasseSearchFilters() {
  const { queryParams } = useSearchPageParams();

  const filter: DayPassFilterType = useMemo(() => {
    return getDayPassFilterFromQueryParams(queryParams);
  }, [queryParams.startDay, queryParams.endDay]);

  return {
    filter,
  };
}
