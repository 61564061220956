import { useEffect, useState } from 'react';

import useQueryParams from '@/hooks/useQueryParams';
import { useUserLocalization } from '@/hooks/useUserLocalization';
import { getDefaultLatLng } from '@/lib/geolocation';
import { LatLng } from '@/models/LatLng';

import { getLatLngFromQuery } from './utils';

export function useLatLng() {
  const { queryParams } = useQueryParams();
  const [latLng, setLatLng] = useState<LatLng>(getDefaultLatLng());
  const [initialLatLng, setInitialLatLng] = useState<LatLng>(
    getDefaultLatLng()
  );
  const { lnglat: userGeoLoc } = useUserLocalization();

  useEffect(() => {
    const latlngFromQuery = getLatLngFromQuery(queryParams);
    if (latlngFromQuery) {
      setLatLng(latlngFromQuery);
      setInitialLatLng(latlngFromQuery);
      return;
    }
  }, []);

  useEffect(() => {
    if (latLng != getDefaultLatLng() && !!userGeoLoc) {
      setLatLng(userGeoLoc);
      setInitialLatLng(userGeoLoc);
      return;
    }
  }, [userGeoLoc]);

  return {
    latLng,
    initialLatLng,
    setLatLng,
  };
}
