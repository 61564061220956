import clsx from 'clsx';

type PickerModeButtonProps = React.ComponentPropsWithoutRef<'button'> & {
  isActive: boolean;
};

export function PickerModeButton({
  isActive,
  children,
  ...rest
}: PickerModeButtonProps) {
  return (
    <button
      className={clsx(
        'w-full py-1 text-grey-30 text-center text-sm font-bold outline-none',
        isActive && 'text-teal-40 border-teal-40 border-b-2',
        !isActive && 'border-grey-60 border-b-[1px] pb-[calc(0.25rem+1px)]'
      )}
      {...rest}
    >
      {children}
    </button>
  );
}
