import { SearchModalType } from '@/app/[locale]/(search)/_components/SearchPageWrapper';
import { CameraMapOptions } from '@/components/map/types';
import { useRouter } from '@/hooks/useRouter';
import { SimpleLocationSearchDto } from '@/lib/locations/dto/simple-location-search.dto';
import { createLocationUrl } from '@/utils/locations';

import SearchMobileFilter from '../filters/SearchMobileFilter';
import SearchFiltersInputWrapper from './SearchFiltersInputWrapper';

type LocationFiltersProps = {
  onClickResultItem: (value: CameraMapOptions, query: string) => void;
  openModal: (type: SearchModalType) => void;
};

export default function LocationFilters({
  onClickResultItem,
  openModal,
}: LocationFiltersProps) {
  const router = useRouter();
  const handleClickLocation = (location: SimpleLocationSearchDto) => {
    router.push(createLocationUrl(location));
  };

  return (
    <>
      <SearchMobileFilter
        detailsText="" // TODO: Implement it
        onClickSearchButton={() => {
          openModal(SearchModalType.PrivateOfficeFilters);
        }}
      />
      <SearchFiltersInputWrapper
        className="w-[520px]"
        onClickLocation={handleClickLocation}
        onClickResultItem={onClickResultItem}
      >
        <div id="locations-filters" data-testid="locations-filters">
          {/* TODO: Implement LocationFilters */}
        </div>
      </SearchFiltersInputWrapper>
    </>
  );
}
