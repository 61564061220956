import { IconProps } from './types';

export default function FilterIcon({ color, ...props }: IconProps) {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <rect x="1" y="0.5" width="29" height="29" rx="14.5" fill="#FFFFFF" />
      <rect x="1" y="0.5" width="29" height="29" rx="14.5" stroke={color} />
      <path
        d="M7.56275 12.4251H10.1685C10.4599 13.8964 11.7456 15 13.2713 15C14.797 15 16.0999 13.8964 16.3913 12.4251H23.437C23.7799 12.4251 24.0713 12.1448 24.0713 11.7769C24.0713 11.4091 23.7799 11.1288 23.437 11.1288H16.3913C16.0999 9.67495 14.8142 8.57141 13.2713 8.57141C11.7285 8.57141 10.4599 9.67495 10.1685 11.1463H7.56275C7.2199 11.1463 6.92847 11.4266 6.92847 11.7945C6.92847 12.1623 7.2199 12.4251 7.56275 12.4251ZM13.2713 9.85012C14.317 9.85012 15.1742 10.7084 15.1742 11.7769C15.1742 12.8454 14.317 13.7038 13.2713 13.7038C12.2256 13.7038 11.3685 12.8454 11.3685 11.7769C11.3685 10.7084 12.2256 9.85012 13.2713 9.85012Z"
        fill={color}
      />
      <path
        d="M23.437 17.5749H20.8313C20.5399 16.1035 19.2542 15 17.7285 15C16.2028 15 14.8999 16.1035 14.6085 17.5749H7.56275C7.2199 17.5749 6.92847 17.8552 6.92847 18.223C6.92847 18.5734 7.2199 18.8712 7.56275 18.8712H14.6085C14.8999 20.325 16.1856 21.4286 17.7285 21.4286C19.2713 21.4286 20.5399 20.325 20.8313 18.8536H23.437C23.7799 18.8536 24.0713 18.5734 24.0713 18.2055C24.0713 17.8552 23.7799 17.5749 23.437 17.5749ZM17.7285 20.1499C16.6828 20.1499 15.8256 19.2916 15.8256 18.223C15.8256 17.1545 16.6828 16.2962 17.7285 16.2962C18.7742 16.2962 19.6313 17.1545 19.6313 18.223C19.6313 19.274 18.7742 20.1499 17.7285 20.1499Z"
        fill={color}
      />
    </svg>
  );
}
