'use client';

import { useEffect } from 'react';

export function useDisableScroll(shouldDisable = false) {
  useEffect(() => {
    if (shouldDisable) {
      document.body.style.overflow = 'hidden';

      return () => {
        document.body.style.overflow = '';
      };
    }
  }, [shouldDisable]);
}
