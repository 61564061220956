import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import { SearchModalType } from '@/app/[locale]/(search)/_components/SearchPageWrapper';
import DayPassFilters, {
  DayPassFilterType,
} from '@/app/[locale]/(search)/coworking/_components/DayPassFilters';
import { createDateRangeQueryParams } from '@/app/[locale]/(search)/coworking/utils';
import { CameraMapOptions } from '@/components/map/types';
import { useDateUtils } from '@/hooks/useDateUtils';
import { SimpleLocationSearchDto } from '@/lib/locations/dto/simple-location-search.dto';
import { DEFAULT_MAP_LOCATION_MR_SEARCH_ZOOM } from '@/utils/constants';

import SearchMobileFilter from '../filters/SearchMobileFilter';
import useDayPassSearchFilters from '../useDayPassSearchFilters';
import useSearchPageParams from '../useSearchPageParams';
import SearchFiltersInputWrapper from './SearchFiltersInputWrapper';

type CoworkingFiltersProps = {
  onClickResultItem: (value: CameraMapOptions, query: string) => void;
  openModal: (type: SearchModalType) => void;
};

export default function CoworkingFilters({
  onClickResultItem,
  openModal,
}: CoworkingFiltersProps) {
  const t = useTranslations('search');
  const { updateQuery } = useSearchPageParams();
  const { filter } = useDayPassSearchFilters();
  const { format } = useDateUtils();

  const dateRangeLabel = useMemo(() => {
    if (!filter.dateRange) return t('day-pass-date');

    if (filter.dateRange.from.getTime() === filter.dateRange.to.getTime()) {
      return t('day-pass-value', {
        date: format(filter.dateRange.from, 'EEE, MMM d'),
      });
    }

    return t('day-pass-value', {
      date: `${format(filter.dateRange.from, 'EEE, MMM d')} - ${format(
        filter.dateRange.to,
        'EEE, MMM d'
      )}`,
    });
  }, [filter]);

  const handleClickLocation = (location: SimpleLocationSearchDto) => {
    onClickResultItem(
      {
        center: [location.geolocalization.lng, location.geolocalization.lat],
        zoom: DEFAULT_MAP_LOCATION_MR_SEARCH_ZOOM,
      },
      location.name
    );
  };

  const handleFilterChange = (filter: DayPassFilterType) => {
    updateQuery(createDateRangeQueryParams(filter.dateRange));
  };

  return (
    <>
      <SearchMobileFilter
        detailsText={dateRangeLabel}
        hasFilters={!!filter.dateRange}
        onClickSearchButton={() => {
          openModal(SearchModalType.LocationDateRange);
        }}
      />
      <SearchFiltersInputWrapper
        className="w-full max-w-[684px]"
        onClickLocation={handleClickLocation}
        onClickResultItem={onClickResultItem}
      >
        <div
          id="coworking-filters"
          data-testid="coworking-filters"
          className="pl-2"
        >
          <DayPassFilters
            filter={filter}
            onFilterChange={handleFilterChange}
            dateRangeLabel={dateRangeLabel}
          />
        </div>
      </SearchFiltersInputWrapper>
    </>
  );
}
