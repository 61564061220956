import { useTranslations } from 'next-intl';
import Image from 'next/image';

import listIcon from '@/public/icons/listIcon.svg';
import mapIcon from '@/public/icons/mapIcon.svg';

import { PrimaryButton } from '../buttons';
import { SearchViewMode } from './types';

type SwitchViewButtonProps = {
  onClick: () => void;
  viewMode: SearchViewMode;
};

export default function SwitchViewButton({
  onClick,
  viewMode,
}: SwitchViewButtonProps) {
  const t = useTranslations('search');

  return (
    <PrimaryButton
      sm
      className="fixed left-0 right-0 py-2 m-auto shadow w-28 bottom-5 lg:hidden z-[2]"
      onClick={onClick}
      data-testid="switch-view-map-button"
    >
      {viewMode === SearchViewMode.List ? (
        <>
          <Image src={mapIcon} alt="map Icon" />
          {t('map')}
        </>
      ) : (
        <>
          <Image src={listIcon} alt="list Icon" />
          {t('list')}
        </>
      )}
    </PrimaryButton>
  );
}
