import { Popover } from '@industriousoffice/react-components';
import { format } from 'date-fns';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useMemo, useState } from 'react';

import calendarIcon from '@/public/icons/calendar.svg';
import { formatTime } from '@/utils/date-utils';

import FilterInputButton from '../../SearchBar/FilterInputButton';
import { AvailabilityFormType } from './types';

import clsx from 'clsx';

const AvailabilityForm = dynamic(() => import('./AvailabilityForm'));

type AvailabilityFilterProps = {
  hourMin: number;
  minDurationInHours: number;
  hourMax: number;
  value: AvailabilityFormType | undefined | null;
  onChange: (data: AvailabilityFormType | null) => void;
};

export default function AvailabilityFilter({
  hourMin,
  hourMax,
  minDurationInHours,
  value,
  onChange,
}: AvailabilityFilterProps) {
  const t = useTranslations('general');
  const [isPopoverOpen, setIsPopperOpen] = useState(false);

  const valueLabel = useMemo(() => {
    if (!value?.date) return t('empty-date-filter');

    const day = format(value.date, 'EEE, MMM d');

    if (value.startTime && value.endTime) {
      const startTime = formatTime(value.startTime);
      const endTime = formatTime(value.endTime);
      return t('date-filter', { day, startTime, endTime });
    }

    return day;
  }, [value]);

  const handleSubmit = (data: AvailabilityFormType | null) => {
    onChange(data);

    if (data) {
      setIsPopperOpen(false);
    }
  };

  return (
    <Popover
      className={clsx(isPopoverOpen && 'top-[13px]')}
      isOpen={isPopoverOpen}
      onOpenChange={() => setIsPopperOpen(!isPopoverOpen)}
      align="start"
      content={
        <AvailabilityForm
          hourMin={hourMin}
          hourMax={hourMax}
          minDurationInHours={minDurationInHours}
          value={value}
          onSubmit={handleSubmit}
        />
      }
    >
      <FilterInputButton
        className="pl-2 lg:w-[150px] tablet-landscape:w-[200px] xl:w-[250px] 2xl:w-[300px]"
        data-testid="availability-filter-btn"
        icon={<Image src={calendarIcon} alt="Calendar icon" />}
      >
        {valueLabel}
      </FilterInputButton>
    </Popover>
  );
}
