import React from 'react';

import { CameraMapOptions } from '@/components/map/types';
import { LatLng } from '@/models/LatLng';

import { SearchModalType } from './SearchPageWrapper';

type SearchContextType = {
  isSearchLoading: boolean;
  searchModalType: SearchModalType | null;
  isFullScreenMap: boolean;
  onLatLngChange: (latLng: LatLng) => void;
  onIsSearchLoadingChange: (mode: boolean) => void;
  onSearchInputItemClick: (
    coords: CameraMapOptions,
    itemValue: string,
    updateSearchParams?: boolean
  ) => void;
  openModal: (type: SearchModalType) => void;
  onCloseModal: () => void;
};

const SearchContext = React.createContext<SearchContextType>({
  isSearchLoading: false,
  searchModalType: null,
  isFullScreenMap: false,
  onLatLngChange: () => {
    //
  },
  onIsSearchLoadingChange: () => {
    //
  },
  onSearchInputItemClick: () => {
    //
  },
  openModal: () => {
    //
  },
  onCloseModal: () => {
    //
  },
});

export default SearchContext;
