import Image from 'next/image';

import DayOrMultiDayPicker from '@/components/DayOrMultiDayPicker/DayOrMultiDayPicker';
import FilterInputButton from '@/components/search/SearchBar/FilterInputButton';
import { DateRange } from '@/models/DateRange';
import calendarIcon from '@/public/icons/calendar.svg';
import {
  futureMonthsDisabled,
  pastDaysBeforeCutoffDisabled,
  weekEndDisabled,
} from '@/utils/date-picker-utils';

export type DayPassFilterType = {
  dateRange?: DateRange;
};

type DayPassFilterProps = {
  filter: DayPassFilterType;
  onFilterChange: (filter: DayPassFilterType) => void;
  dateRangeLabel?: string;
};

export default function DayPassFilters({
  filter,
  onFilterChange,
  dateRangeLabel,
}: DayPassFilterProps) {
  const { dateRange } = filter;

  const handleDateRangeChange = (dateRange: DateRange | undefined) => {
    onFilterChange({
      ...filter,
      dateRange: dateRange ?? undefined,
    });
  };

  return (
    <DayOrMultiDayPicker
      popoverClassName="top-[13px] -left-[25px]"
      popupTestId="availability-filter-picker"
      selected={dateRange}
      defaultMonth={dateRange?.from}
      renderIcon={() => (
        <Image
          data-testid="calendar-icon"
          src={calendarIcon}
          className="mr-2"
          alt={'calendar-icon'}
          aria-hidden="true"
        />
      )}
      disabled={[
        weekEndDisabled,
        pastDaysBeforeCutoffDisabled,
        futureMonthsDisabled,
      ]}
      onChange={handleDateRangeChange}
    >
      <FilterInputButton
        className="min-w-[150px] tablet-landscape:w-[230px]"
        data-testid="daypass-date-picker"
        icon={<Image src={calendarIcon} alt="calendar-icon" />}
      >
        {dateRangeLabel}
      </FilterInputButton>
    </DayOrMultiDayPicker>
  );
}
