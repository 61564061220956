import {
  DatePicker,
  RangeDatePickerProps,
  SingleDatePickerProps,
} from '@industriousoffice/react-components';
import { SelectedDays } from '@industriousoffice/react-components/types/components/DatePicker/components/Picker';
import { useTranslations } from 'next-intl';
import React, { useState } from 'react';

import { DateRange, PartialDateRange } from '@/models/DateRange';

import { PickerModeButton } from '../search/filters/PickerModeButton';
import DayOrMultiDayPickerMode from './mode.enum';

type DayOrMultiDayPickerProps = Omit<
  SingleDatePickerProps | RangeDatePickerProps,
  'selected' | 'onChange' | 'onUnconfirmedChange'
> & {
  popupTestId?: string;
  popoverClassName?: string;
  multipleDayDescription?: string;
  singleDayDescription?: string;
  selected: PartialDateRange | undefined;
  buttonTestId?: string;
  onChange: (
    value: DateRange | undefined,
    mode: DayOrMultiDayPickerMode
  ) => void;
  onUnconfirmedChange?: (value: PartialDateRange | undefined) => void;
};

function DayOrMultiDayPicker({
  popupTestId,
  popoverClassName,
  selected,
  multipleDayDescription,
  singleDayDescription,
  onChange,
  onUnconfirmedChange,
  buttonTestId,
  ...rest
}: DayOrMultiDayPickerProps) {
  const t = useTranslations('general');
  const [mode, setMode] = useState<DayOrMultiDayPickerMode>(() => {
    if (!selected) return DayOrMultiDayPickerMode.Single;
    return selected.from.getTime() === selected.to?.getTime()
      ? DayOrMultiDayPickerMode.Single
      : DayOrMultiDayPickerMode.Range;
  });
  const isSingleMode = mode === DayOrMultiDayPickerMode.Single;
  const isRangeMode = mode === DayOrMultiDayPickerMode.Range;
  const selectedAdapted = isSingleMode ? selected?.from : selected;

  const setModeSingle = () => {
    setMode(DayOrMultiDayPickerMode.Single);
    onChange(
      selected?.from && { from: selected.from, to: selected.from },
      mode
    );
  };

  const setModeRange = () => {
    setMode(DayOrMultiDayPickerMode.Range);
  };

  const comment =
    (isRangeMode && multipleDayDescription) ||
    (isSingleMode && singleDayDescription);

  const handleOnChange = (value: SelectedDays) => {
    if (isSingleMode && value instanceof Date) {
      onChange({ from: value as Date, to: value as Date }, mode);
      return;
    }
    if (isRangeMode && value?.from instanceof Date) {
      onChange({ from: value.from, to: value.to ?? value.from }, mode);
      return;
    }
    onChange(undefined, mode);
  };

  const handleOnUnconfirmedChange = (value: SelectedDays) => {
    if (!onUnconfirmedChange) return;

    if (isSingleMode && value instanceof Date) {
      onUnconfirmedChange({ from: value as Date, to: value as Date });
      return;
    }
    if (isRangeMode && value?.from instanceof Date) {
      onUnconfirmedChange({ from: value.from, to: value.to });
      return;
    }
    onUnconfirmedChange(undefined);
  };

  return (
    <DatePicker
      popupTestId={popupTestId}
      popupTopChildren={
        <>
          <div className="flex pt-3 mx-1">
            <PickerModeButton
              isActive={isSingleMode}
              onClick={setModeSingle}
              data-testid="singleday-tab"
            >
              {t('single-day')}
            </PickerModeButton>
            <PickerModeButton
              isActive={isRangeMode}
              onClick={setModeRange}
              data-testid="multiday-tab"
            >
              {t('multiple-days')}
            </PickerModeButton>
          </div>
          {comment && (
            <p className="mt-3 mb-[-18px] text-sm text-center text-grey-25">
              {comment}
            </p>
          )}
        </>
      }
      selected={selectedAdapted}
      className="min-w-[150px]"
      popoverClassName={popoverClassName}
      mode={mode}
      withReset
      resetText={t(isSingleMode ? 'single-day-reset' : 'multiple-day-reset')}
      withConfirmation
      confirmationText={t(
        isSingleMode ? 'single-day-confirm' : 'multiple-day-confirm'
      )}
      onChange={handleOnChange}
      onUnconfirmedChange={handleOnUnconfirmedChange}
      buttonTestId={buttonTestId}
      {...rest}
    />
  );
}

export default DayOrMultiDayPicker;
