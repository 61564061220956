import { useTranslations } from 'next-intl';

import { CameraMapOptions } from '@/components/map/types';
import { SimpleLocationSearchDto } from '@/lib/locations';

import LocationSearchInput from '../SearchInput';
import { SearchType } from '../search-type.enum';
import SearchButton from './SearchButton';

import clsx from 'clsx';

type SearchFiltersInputWrapperProps = {
  children: React.ReactNode;
  className?: string;
  onClickLocation: (location: SimpleLocationSearchDto) => void;
  onClickResultItem: (value: CameraMapOptions, query: string) => void;
};

export default function SearchFiltersInputWrapper({
  children,
  className,
  onClickLocation,
  onClickResultItem,
}: SearchFiltersInputWrapperProps) {
  const t = useTranslations('search');

  return (
    <div
      className={clsx(
        'border rounded-full border-grey-70 h-[45px] flex max-lg:hidden z-[3] my-4',
        className
      )}
    >
      <div className="flex items-center w-full divide-x divide-grey-70">
        <div className="lg:min-w-[200px] xl:min-w-[290px]">
          <LocationSearchInput
            className="h-full text-base"
            controlClassName="px-5"
            onClickLocation={onClickLocation}
            onClickResultItem={onClickResultItem}
            placeholder={t('placeholder-input-short')}
            searchType={SearchType.Location}
          />
        </div>

        {children}
      </div>
      <SearchButton />
    </div>
  );
}
