import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import FilterIcon from '@/components/icons/FilterIcon';
import LoepIcon from '@/components/icons/LoepIcon';
import theme from '@/theme/theme.generated.json';

import useSearchPageParams from '../useSearchPageParams';
import { getSearchValueFromQuery } from '../utils';

type SearchMobileFilterProps = {
  showFilterButton?: boolean;
  detailsText: string;
  hasFilters?: boolean;
  onClickSearchButton?: () => void;
  onClickFilterButton?: () => void;
};

export default function SearchMobileFilter({
  showFilterButton,
  detailsText,
  hasFilters,
  onClickSearchButton,
  onClickFilterButton,
}: SearchMobileFilterProps) {
  const t = useTranslations('search');
  const { queryParams } = useSearchPageParams();

  const searchValue = useMemo(() => {
    const value = getSearchValueFromQuery(queryParams.query);
    return value || t('current-location');
  }, [queryParams.query]);

  return (
    <div className="flex items-center justify-between w-full max-w-[700px] gap-4 lg:hidden">
      <button
        className="rounded-full shadow h-[58px] flex-1 px-6 flex items-center text-sm gap-[10px]"
        onClick={onClickSearchButton}
        data-testid="mobile-search-button"
      >
        <LoepIcon
          className="max-lg:mr-2 fill-black lg:hidden"
          width={15}
          height={15}
        />
        <div className="flex flex-col items-start">
          <span className="mb-[1px] font-bold">{searchValue}</span>
          <span className="text-xs text-grey-25">{detailsText}</span>
        </div>
      </button>
      {showFilterButton && (
        <button
          className="relative"
          onClick={onClickFilterButton}
          data-testid="mobile-filter-button"
        >
          {hasFilters && (
            <div className="absolute right-0 block w-3 h-3 rounded-full -top-1 bg-teal-40 z-[1]" />
          )}
          <FilterIcon
            color={
              hasFilters ? theme.colors.teal['40'] : theme.colors.grey['25']
            }
          />
        </button>
      )}
    </div>
  );
}
