import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import { SearchModalType } from '@/app/[locale]/(search)/_components/SearchPageWrapper';
import {
  HOUR_MAX,
  HOUR_MIN,
  MIN_DURATION_IN_HOURS,
} from '@/app/[locale]/(search)/meeting-rooms/_components/constants';
import { MeetingRoomFilterType } from '@/app/[locale]/(search)/meeting-rooms/_components/types';
import { createFilterQueryParams } from '@/app/[locale]/(search)/meeting-rooms/_components/utils';
import { CameraMapOptions } from '@/components/map/types';
import { SimpleLocationSearchDto } from '@/lib/locations/dto/simple-location-search.dto';
import { DEFAULT_MAP_LOCATION_MR_SEARCH_ZOOM } from '@/utils/constants';
import { format, formatTime } from '@/utils/date-utils';

import AvailabilityFilter from '../filters/AvailabilityFilter';
import MeetingRoomSeatsFilter from '../filters/MeetingRoomSeatsFilter';
import SearchMobileFilter from '../filters/SearchMobileFilter';
import useMeetingRoomFilters from '../useMeetingRoomFilters';
import useSearchPageParams from '../useSearchPageParams';
import SearchFiltersInputWrapper from './SearchFiltersInputWrapper';

type MeetingRoomFiltersProps = {
  onClickResultItem: (value: CameraMapOptions, query: string) => void;
  openModal: (type: SearchModalType) => void;
};

export default function MeetingRoomFilters({
  onClickResultItem,
  openModal,
}: MeetingRoomFiltersProps) {
  const t = useTranslations('search');
  const { updateQuery } = useSearchPageParams();

  const { filter } = useMeetingRoomFilters();

  const dateValue = useMemo(() => {
    if (!filter.dateTime?.date) return t('empty-date-filter');

    const day = format(filter.dateTime.date, 'EEE, MMM d');

    if (filter.dateTime.startTime && filter.dateTime.endTime) {
      const startTime = formatTime(filter.dateTime.startTime);
      const endTime = formatTime(filter.dateTime.endTime);
      return t('date-filter', { day, startTime, endTime });
    }

    return day;
  }, [filter.dateTime]);

  const handleFilterChange = (filter: MeetingRoomFilterType) => {
    updateQuery(createFilterQueryParams(filter));
  };

  const handleClickLocation = (location: SimpleLocationSearchDto) => {
    onClickResultItem(
      {
        center: [location.geolocalization.lng, location.geolocalization.lat],
        zoom: DEFAULT_MAP_LOCATION_MR_SEARCH_ZOOM,
      },
      location.name
    );
  };

  return (
    <>
      <SearchMobileFilter
        showFilterButton
        detailsText={dateValue}
        hasFilters={
          (filter.seats && filter.seats.length > 0) ||
          (filter.amenities && filter.amenities.length > 0)
        }
        onClickSearchButton={() => {
          openModal(SearchModalType.LocationDateTime);
        }}
        onClickFilterButton={() => {
          openModal(SearchModalType.Filters);
        }}
      />
      <SearchFiltersInputWrapper
        onClickLocation={handleClickLocation}
        onClickResultItem={onClickResultItem}
      >
        <div
          id="meeting-rooms-filters"
          data-testid="meeting-rooms-filters"
          className="flex pl-2 divide-x xl:flex-1 divide-grey-70"
        >
          <MeetingRoomSeatsFilter
            value={filter.seats}
            onChange={(seats) => handleFilterChange({ ...filter, seats })}
          />
          <AvailabilityFilter
            hourMin={HOUR_MIN}
            hourMax={HOUR_MAX}
            minDurationInHours={MIN_DURATION_IN_HOURS}
            value={filter.dateTime}
            onChange={(dateTime) =>
              handleFilterChange({ ...filter, dateTime: dateTime ?? undefined })
            }
          />
        </div>
      </SearchFiltersInputWrapper>
    </>
  );
}
