'use client';

import { useLocale } from 'next-intl';
import { useEffect } from 'react';

import * as dateUtils from '@/utils/date-utils';

export function useDateUtils() {
  const locale = useLocale();

  useEffect(() => {
    dateUtils.setLocale(locale);
  }, [locale]);

  return dateUtils;
}
