import clsx from 'clsx';

type SearchPageLayoutProps = {
  children: React.ReactNode;
  isFullScreenMap: boolean;
  withMap?: boolean;
};

export default function SearchPageLayout({
  children,
  isFullScreenMap,
  withMap,
}: SearchPageLayoutProps) {
  return (
    <main
      className={clsx(
        withMap && 'lg:overflow-hidden lg:h-[calc(100vh-145px)]',
        withMap && isFullScreenMap && 'h-screen'
      )}
    >
      {children}
    </main>
  );
}
