'use client';

import { useEffect, useState } from 'react';

import { CameraMapOptions } from '@/components//map/types';
import SearchBar from '@/components/search/SearchBar/SearchBar';
import Layout from '@/components/search/SearchPageLayout';
import SwitchViewButton from '@/components/search/SwitchViewButton';
import { SearchViewMode } from '@/components/search/types';
import { useLatLng } from '@/components/search/useLatLng';
import useSearchPageParams from '@/components/search/useSearchPageParams';
import { createLocalizationQueryParams } from '@/components/search/utils';
import { useDisableScroll } from '@/hooks/useDisableScroll';
import { ScreenSize, useScreenSize } from '@/hooks/useScreenSize';
import { useWebGL } from '@/hooks/useWebGL';
import { LatLng } from '@/models/LatLng';

import SearchContext from './SearchContext';

export type SearchPageWrapperProps = {
  children: React.ReactNode;
};

export enum SearchModalType {
  LocationDateRange = 'location-date-range',
  LocationDateTime = 'location-date-time',
  Filters = 'filters',
  PrivateOfficeFilters = 'private-office-filters',
}

export default function SearchPageWrapper({
  children,
}: SearchPageWrapperProps) {
  const { isWebGLSupported } = useWebGL();

  const { queryParams, updateQuery } = useSearchPageParams();
  const widthWindow = useScreenSize();

  const [viewMode, setViewMode] = useState<SearchViewMode>(
    queryParams.view ?? SearchViewMode.List
  );
  const [searchModalType, setSearchModalType] =
    useState<SearchModalType | null>(null);

  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const { latLng, setLatLng } = useLatLng();

  useDisableScroll(viewMode === SearchViewMode.Map);

  useEffect(() => {
    if (widthWindow < ScreenSize.LG) return;
    if (queryParams.view) {
      updateQuery({ view: undefined });
      setViewMode(SearchViewMode.List);
    }
  }, [widthWindow, queryParams.view]);

  const handleSearchInputItemClick = (
    coords: CameraMapOptions,
    itemValue: string,
    updateSearchParams = true
  ) => {
    setIsSearchLoading(true);

    if (updateSearchParams) {
      const querySearch = createLocalizationQueryParams(coords, itemValue);
      updateQuery(querySearch);
    }

    const [longitude, latitude] = coords.center as [number, number];
    setLatLng({ lat: latitude, lng: longitude });
  };

  const handleLatLngChange = (newLatLng: LatLng) => {
    if (latLng.lat != newLatLng.lat || latLng.lng != newLatLng.lng) {
      setLatLng(newLatLng);
    }
  };

  const handleOpenModal = (type: SearchModalType) => {
    setSearchModalType(type);
  };

  return (
    <SearchContext.Provider
      value={{
        isSearchLoading,
        searchModalType,
        isFullScreenMap: viewMode === SearchViewMode.Map,
        onIsSearchLoadingChange: (value) => setIsSearchLoading(value),
        onLatLngChange: handleLatLngChange,
        onSearchInputItemClick: handleSearchInputItemClick,
        openModal: handleOpenModal,
        onCloseModal: () => setSearchModalType(null),
      }}
    >
      <SearchBar
        openModal={handleOpenModal}
        onClickResultItem={handleSearchInputItemClick}
      />
      <Layout
        isFullScreenMap={viewMode === SearchViewMode.Map}
        withMap={isWebGLSupported === undefined || isWebGLSupported === true}
      >
        <div className="flex flex-col h-full lg:flex-row">{children}</div>
        <SwitchViewButton
          onClick={() => {
            window.scroll(0, 0);
            const nextViewMode =
              viewMode === SearchViewMode.List
                ? SearchViewMode.Map
                : SearchViewMode.List;
            setViewMode(nextViewMode);
            updateQuery({
              view: nextViewMode,
            });
          }}
          viewMode={viewMode}
        />
      </Layout>
    </SearchContext.Provider>
  );
}
