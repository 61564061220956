import { SearchModalType } from '@/app/[locale]/(search)/_components/SearchPageWrapper';
import { CameraMapOptions } from '@/components/map/types';

import { SearchType } from '../search-type.enum';
import useSearchPageParams from '../useSearchPageParams';
import CoworkingFilters from './CoworkingFilters';
import LocationFilters from './LocationFilters';
import MeetingRoomFilters from './MeetingRoomFilters';
import SearchNav from './SearchNav';

type SearchBarProps = {
  onClickResultItem: (value: CameraMapOptions, query: string) => void;
  openModal: (type: SearchModalType) => void;
};

export default function SearchBar({
  onClickResultItem,
  openModal,
}: SearchBarProps) {
  const { searchType } = useSearchPageParams();

  return (
    <header className="bg-white max-lg:shadow lg:border-b lg:border-grey-70">
      <div className="flex items-center gap-6 lg:gap-10 max-lg:flex-col large-container max-lg:py-5">
        <SearchNav />

        {searchType === SearchType.Location && (
          <LocationFilters
            onClickResultItem={onClickResultItem}
            openModal={openModal}
          />
        )}
        {searchType === SearchType.Coworking && (
          <CoworkingFilters
            onClickResultItem={onClickResultItem}
            openModal={openModal}
          />
        )}
        {searchType === SearchType.MeetingRoom && (
          <MeetingRoomFilters
            onClickResultItem={onClickResultItem}
            openModal={openModal}
          />
        )}
      </div>
    </header>
  );
}
