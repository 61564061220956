'use client';

import { useTranslations } from 'next-intl';

import LoepIcon from '@/components/icons/LoepIcon';

export default function SearchButton() {
  const t = useTranslations('search');

  return (
    <button
      className="m-[2px] rounded-full bg-teal-40 flex items-center justify-center text-white px-5 gap-2 font-bold"
      data-testid="search-button-filters"
      aria-label={t('search-locations-aria-label')}
    >
      <LoepIcon className="fill-white" />
      {t('search-cta')}
    </button>
  );
}
