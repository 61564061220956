import { useTranslations } from 'next-intl';

import { PrimaryButton } from '@/components/buttons/PrimaryButton';

type FilterCtaProps = {
  dataTestId: string;
  onClearLink: () => void;
  onApplyBtn: () => void;
};

export default function FilterCtas({
  dataTestId,
  onClearLink,
  onApplyBtn,
}: FilterCtaProps) {
  const t = useTranslations('general');

  return (
    <div
      className="flex items-center justify-between mt-2"
      data-testid={`${dataTestId}-filter-ctas`}
    >
      <a
        className="text-sm cursor-pointer text-teal-50 hover:underline"
        onClick={onClearLink}
        data-testid={`${dataTestId}-clear-btn`}
      >
        {t('clear')}
      </a>
      <PrimaryButton
        className="leading-none"
        sm
        onClick={onApplyBtn}
        type="submit"
        data-testid={`${dataTestId}-apply-btn`}
      >
        {t('apply')}
      </PrimaryButton>
    </div>
  );
}
