import { AvailabilityFormType } from '@/components/search/filters/AvailabilityFilter/types';
import { MeetingRoomPriceDto } from '@/lib/meeting-rooms/dto/search-meeting-rooms-prices.dto';
import { SimpleMeetingRoomDto } from '@/lib/meeting-rooms/dto/simple-meeting-room.dto';

export type MeetingRoomFilterType = {
  seats?: MinMaxFilter[];
  amenities?: string[];
  dateTime?: AvailabilityFormType;
};

export type MinMaxFilter = {
  min: number | undefined;
  max: number | undefined;
};

export const SEATS_FILTERS: MinMaxFilter[] = [
  { min: 1, max: 4 },
  { min: 5, max: 9 },
  { min: 10, max: 19 },
  { min: 20, max: undefined },
];

export type MeetingRoomWithPrice = SimpleMeetingRoomDto & {
  prices?: MeetingRoomPriceDto;
};
