'use client';

import { useTranslations } from 'next-intl';

import { useSupplier } from '@/hooks/useSupplier';
import { Link } from '@/navigation';
import { stringifyUrl } from '@/utils/stringify-url';

import useSearchPageParams from '../useSearchPageParams';

import clsx from 'clsx';

const searchNav = [
  {
    key: 'office-space',
    type: 'location',
    testid: 'offices-tab',
    href: '/locations',
  },
  {
    key: 'coworking',
    type: 'coworking',
    testid: 'daypasses-tab',
    href: '/coworking',
  },
  {
    key: 'meeting-room',
    type: 'meeting-room',
    testid: 'mr-tab',
    href: '/meeting-rooms',
  },
];

export default function SearchNav() {
  const t = useTranslations('search');
  const { searchType, queryParams } = useSearchPageParams();
  const { isNuveenSupplier } = useSupplier();

  if (isNuveenSupplier) {
    return null;
  }

  return (
    <ul
      id="search-nav"
      className="grid w-full grid-cols-3 gap-1 max-w-[410px] sm:flex justify-between lg:self-stretch lg:max-w-[325px] tablet-landscape:max-w-[380px] xl:max-w-[410px]"
    >
      {searchNav.map((nav) => {
        const isActive = searchType === nav.type;

        return (
          <li key={nav.key}>
            <Link
              className={clsx(
                'flex flex-col items-center gap-2 h-full justify-center text-sm sm:text-base text-center pb-1 border-b-[3px] pt-[4px]',
                'lg:text-sm xl:text-base',
                isActive
                  ? 'text-teal-40 font-semibold px-[1px] border-teal-40'
                  : 'text-grey-25 hover:text-teal-40 border-transparent'
              )}
              href={stringifyUrl(nav.href, queryParams)}
              data-testid={nav.testid}
            >
              {t(nav.key)}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
