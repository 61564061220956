import clsx from 'clsx';

type FilterOptionProps = {
  onClick: () => void;
  isActive: boolean;
  label: JSX.Element | string;
  className?: string;
};

export function FilterOption({
  onClick,
  isActive,
  label,
  className,
}: FilterOptionProps) {
  return (
    <button
      type="button"
      className={clsx(
        className,
        'text-teal-40 border border-grey-90 px-2 py-1 rounded-full',
        isActive
          ? 'border-2 border-teal-40 bg-[rgba(228,237,236,0.40)]'
          : 'm-[1px]'
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );
}
