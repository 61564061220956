import React from 'react';

import clsx from 'clsx';

type FilterInputButtonProps = {
  icon: JSX.Element;
} & React.ComponentPropsWithoutRef<'button'>;

const FilterInputButton = React.forwardRef<
  HTMLButtonElement,
  FilterInputButtonProps
>(({ children, icon, className, ...rest }, ref) => {
  return (
    <button
      ref={ref}
      className={clsx('flex items-center w-full', className)}
      {...rest}
    >
      {icon}
      <span className="ml-2 truncate">{children}</span>
    </button>
  );
});
FilterInputButton.displayName = 'FilterInputButton';

export default FilterInputButton;
